/*
 * @Date: 2022-03-16 14:13:48
 * @LastEditTime: 2022-03-21 11:02:50
 * @FilePath: \hc_website\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
// 引入插件
import createPersistedState from "vuex-persistedstate";
import deviceId from "./userId/deviceId"; // 浏览器标识
export default new Vuex.Store({
  modules: {
    deviceId,
  },
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.localStorage,
      // 存储的 key 的key值
      key: "store",
      render(state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state };
      },
    }),
  ],
});
