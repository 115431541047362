/*
 * @Date: 2022-03-21 10:28:10
 * @LastEditTime: 2022-03-21 10:41:45
 * @FilePath: \hc_website\src\store\userId\deviceId.js
 */
export default {
    namespaced:true,
    state:{
        browser_id:""
    },
    mutations:{
        SET_BROWSER_ID:(state,data)=>{
            state.browser_id = data;
        }
    }
}