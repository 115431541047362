/*
 * @Date: 2022-03-21 18:38:39
 * @LastEditTime: 2022-03-21 18:38:39
 * @FilePath: \hc_website\src\utiles\utile.js
 */
/**
 * @function getElementTop 获取滚动元素高度
 */
export const getElementTop = (element) => {
  var actualTop = element.offsetTop;
  var current = element.offsetParent;

  while (current !== null) {
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }
  return actualTop;
};
