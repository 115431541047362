/*
 * @Date: 2022-03-16 14:13:48
 * @LastEditTime: 2022-03-16 16:21:49
 * @FilePath: \hc_website\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    redirect: '/homePage'
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import('@/views/homePage/index.vue'),
    redirect: '/citylevelDataVisualization',
    children: [
      // 城市级数据可视化
      {
        path: '/citylevelDataVisualization',
        name: 'citylevelDataVisualization',
        component: () => import('@/views/homePage/technicalCapability/citylevelDataVisualization/citylevelDataVisualization.vue')
      },
      // GIS地理信息可视化
      {
        path: '/geographicInformationVisualization',
        name: 'geographicInformationVisualization',
        component: () => import('@/views/homePage/technicalCapability/geographicInformationVisualization/geographicInformationVisualization.vue')
      },
      // 影视级高精度渲染
      {
        path: '/televisionHighPrecisionRendering',
        name: 'televisionHighPrecisionRendering',
        component: () => import('@/views/homePage/technicalCapability/televisionHighPrecisionRendering/televisionHighPrecisionRendering.vue')
      },
      // BIM轻量可视化
      {
        path: '/BIMlightweightVisualization',
        name: 'BIMlightweightVisualization',
        component: () => import('@/views/homePage/technicalCapability/BIMlightweightVisualization/BIMlightweightVisualization.vue')
      }]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
