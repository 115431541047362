/*
 * @Date: 2022-03-16 14:13:48
 * @LastEditTime: 2022-03-24 10:03:36
 * @FilePath: \hc_website\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// video.js样式
import "video.js/dist/video-js.css";
// 自定义css
import "@/css/base.css";
// element ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// rem转换
import "amfe-flexible";
// iconfont
import "@/iconfont/iconfont.css";
Vue.use(ElementUI);
Vue.config.productionTip = false;
// 工具类 && utils
import { getElementTop } from "./utiles/util";
Vue.prototype.getElementTop = getElementTop;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
